import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";
import "./DelaconReportsUpload.css"
import {Spinner} from "reactstrap";
import {uploadDelaconReport} from "../../../services/case-reports.service";

const initialState = {
    loading: false,
    errorMessage: '',
    successMessage: ''
}

function DelaconReportsUpload() {

    const [state, setState] = useState(initialState);


    const onDrop = useCallback(async (acceptedFiles) => {
        setState(st => {
            return {...st, errorMessage: '', loading: true};
        });

        const formData = new FormData();
        acceptedFiles.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
        });

        uploadDelaconReport(formData)
            .then(res => {
                if (res.statusCode >= 400) {
                    setState(st => {
                        return {...st, errorMessage: res.message, successMessage: '', loading: false};
                    });
                } else {
                    setState(st => {
                        return {...st, errorMessage: '', successMessage: 'Files uploaded successfully', loading: false};
                    });
                }
            });
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div className="provision-device-container">
            <h2>Delacon Reports Upload</h2>
            <div>
                <p>Example: I want to generate delacon report for December 2023</p>
                <p>I need to upload these files: </p>
                <p><b>202312-Toyota SOS.csv</b>, <b>202312-Toyota ACN.csv</b>, <b>202312-Toyota SVT.csv</b>, <b>202312-Toyota SVT ENQ.csv</b>, <b>202312-Lexus
                    ACN.csv</b>, <b>202312-Lexus SOS.csv</b>, <b>202312-Lexus SVT.csv</b>, <b>202312-Lexus SVT ENQ.csv</b></p>
                <p>and the assist report file: <b>202312-assistreport.csv</b></p>
                <p>All these files could be upload at once.</p>

            </div>

            {!state.loading &&
                <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                    <input {...getInputProps()} />
                    <p>Drag & drop files here, or click to select files</p>
                </div>
            }

            {
                state.loading &&
                <Spinner animation="border" role="status" className="upload-spinner">
                    <span className="visually-hidden">Uploading...</span>
                </Spinner>
            }

            {
                state.errorMessage !== '' && <p className={`error-message`}>{state.errorMessage}</p>
            }

            {
                state.successMessage !== '' && <p className={`success-message`}>{state.successMessage}</p>
            }
        </div>
    )
}

export default DelaconReportsUpload;
